import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import api from "../utils/api";

export async function usePushNotifications() {
  const addListeners = async () => {
    // Handle the push notifications
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push received: ", notification);
      }
    );

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });
    // Listener when the user taps on a notification
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log("Push action performed: ", notification);
        if (notification.notification?.data?.noteId) {
          window?.router?.push(
            `/note/${notification.notification?.data?.noteId}`
          );
        }
      }
    );
    await PushNotifications.addListener("registration", (token) => {
      // Send the token to your server
      console.log("Push registration success, token:", token.value);
      api.post("/push", {
        token: token.value,
        platform: Capacitor.getPlatform() === "ios" ? "APN" : "FCM",
      });
    });
  };
  await addListeners();
  // Register for push notifications
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === "granted") {
      // Register with the push notification service
      PushNotifications.register();
    }
  });
}
