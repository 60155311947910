<template>
  <Dialog
    header="Enable Two-Factor Authentication"
    :visible="true"
    modal
    @hide="hideDialog()"
    class="w-12 sm:w-8 md:w-6"
  >
    <div class="p-fluid">
      <p v-if="!qrCode">
        We recommend enabling Two-Factor Authentication for added security. Do
        you want to enable it now?
      </p>

      <div v-if="!qrCode" class="field-checkbox">
        <Checkbox
          :modelValue="doNotShowAgain"
          :binary="true"
          @update:modelValue="(val) => (doNotShowAgain = val)"
          inputId="doNotShowAgain"
          label="Do not show me this again"
        />
        <label for="doNotShowAgain">Do not show me this again</label>
      </div>

      <div v-if="!qrCode" class="mt-4 flex justify-content-between">
        <Button
          label="No Thanks"
          outlined
          severity="secondary"
          icon="pi pi-times"
          @click="hideDialog"
        />
        <Button label="Enable 2FA" icon="pi pi-check" @click="enable2FA" />
      </div>

      <div v-if="qrCode" class="qr-code-container mt-4">
        <p class="text-center">
          Scan this QR code with your authenticator app:
        </p>
        <VueQrcode :value="qrCode" class="qr-code" />

        <div class="field mt-4">
          <label for="twoFactorCode"
            >Enter the code from your authenticator app</label
          >
          <InputText
            id="twoFactorCode"
            v-model="twoFactorCode"
            placeholder="Enter 2FA code"
            required
            class="w-full"
          />
        </div>
        <p class="error" v-if="error">{{ error }}</p>
        <Button
          label="Verify & Enable"
          icon="pi pi-check"
          class="w-full mt-3"
          @click="validateTwoFactorCode"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import api from "../utils/api"; // Import API utility

const emit = defineEmits(["onClose"]);
const showDialog = ref(false);
const doNotShowAgain = ref(false);
const qrCode = ref(null); // For the QR code
const twoFactorCode = ref(""); // For the 2FA code input
const error = ref(null);

// Function to enable 2FA and display the QR code
const enable2FA = async () => {
  try {
    const response = await api.post("/auth/enable-2-factor", { enabled: true });
    qrCode.value = response.data.secret; // This would contain the otpauth_url for generating the QR code
    console.log("QR Code Secret:", qrCode.value);
  } catch (error) {
    console.error("Failed to enable 2FA:", error);
  }
};

// Function to validate the 2FA code entered by the user
const validateTwoFactorCode = async () => {
  try {
    error.value = null;
    const response = await api.post("/auth/validate-2-factor", {
      twoFactorToken: twoFactorCode.value,
    });

    if (response.data.success) {
      console.log("2FA successfully enabled!");
      hideDialog();
    } else {
      error.value = "Invalid 2FA code";
      console.error("Invalid 2FA code");
    }
  } catch (error) {
    console.error("Failed to validate 2FA code:", error);
    error.value = "Invalid 2FA code";
  }
};

// Function to hide the dialog
const hideDialog = () => {
  if (doNotShowAgain.value) {
    localStorage.setItem("hide2FADialog", "true");
  }
  showDialog.value = false;
  emit("onClose");
};
</script>

<style scoped>
/* Add any necessary styling here */
canvas {
  margin: 0 auto;
  display: block;
}
</style>
