<template>
  <div class="flex justify-content-center align-items-center login">
    <div class="surface-card p-4 shadow-2 border-round text-left">
      <h2 class="text-center">Login</h2>
      <form @submit.prevent="login" class="p-fluid">
        <div class="field">
          <label for="email">Email</label>
          <InputText
            id="email"
            v-model="email"
            type="email"
            placeholder="Enter email"
            required
            class="w-full"
          />
        </div>
        <div class="field">
          <label for="password">Password</label>
          <Password
            id="password"
            v-model="password"
            placeholder="Enter password"
            toggleMask
            required
            :feedback="false"
            class="w-full"
            inputClass="w-full"
          />
          <router-link to="/forgot-password" class="block mt-3 text-right"
            >Forgot Password?</router-link
          >
        </div>
        <div v-if="errorMessage" class="mt-3 text-center text-red-600">
          {{ errorMessage }}
        </div>
        <Button
          :disabled="authing"
          type="submit"
          label="Login"
          :icon="`pi pi-${!authing ? 'sign-in' : 'spinner pi-spin'} `"
          class="w-full mt-3"
        />
      </form>

      <p class="text-center mt-3">
        Don't have an account?
        <router-link to="/register">Register</router-link>
      </p>
    </div>
    <TwoFactorDialog
      v-if="showTwoFactorEnableDialog"
      @onClose="onAuthSuccess"
    />
    <TwoFactorRequiredDialog
      v-if="showTwoFactorRequiredDialog"
      @onClose="verifyTwoFactorCode"
      @dismiss="showTwoFactorRequiredDialog = false"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import TwoFactorDialog from "../components/2FAPrompt.vue"; // Import the 2FA dialog
import TwoFactorRequiredDialog from "../components/2FARequiredPrompt.vue"; // Import the 2FA dialog

import { useAuthStore } from "../hooks/useAuthStore.js"; // Updated to useAuthStore

const authing = ref(false);
const showTwoFactorEnableDialog = ref(false);
const showTwoFactorRequiredDialog = ref(false);
const email = ref("");
const password = ref("");
const errorMessage = ref("");
const router = useRouter();
const route = useRoute();

const manual = ref(false);
const authStore = useAuthStore(); // Using loginUser method from useAuthStore

const onAuthSuccess = () => {
  router.push(route.query.return_url || "/");
};
watch(
  authStore.isAuthenticated,
  () => {
    if (authStore.isAuthenticated.value === true) {
      if (!manual.value) {
        onAuthSuccess();
      }
    }
  },
  { immediate: true }
);
const login = async () => {
  try {
    manual.value = true;
    authing.value = true;
    errorMessage.value = null;
    const response = await authStore.login({
      email: email.value,
      password: password.value,
    }); // Refactor to use centralized store

    // Check if 2FA is required and if the dialog should be shown
    if (response.data?.twoFactorRequired === true) {
      showTwoFactorRequiredDialog.value = true; // Show 2FA dialog if required
    } else if (
      response.data?.user?.twoFactorEnabled === false &&
      !localStorage.getItem("hide2FADialog")
    ) {
      showTwoFactorEnableDialog.value = true;
    } else {
      onAuthSuccess();
    }
    authing.value = false;
  } catch (error) {
    authing.value = false;
    errorMessage.value =
      error.message || "An unexpected error occurred. Please try again.";
  }
};

// Function to verify the 2FA code after it is entered in the dialog
const verifyTwoFactorCode = async (twoFactorToken) => {
  try {
    const response = await authStore.verifyTwoFactor({
      email: email.value,
      password: password.value,
      twoFactorToken,
    });

    if (response.data.token) {
      onAuthSuccess();
    } else {
      errorMessage.value = "Invalid 2FA code. Please try again.";
    }
  } catch (error) {
    errorMessage.value = "An unexpected error occurred. Please try again.";
  }
};
</script>

<style scoped>
.login a {
  color: var(--primary-clr);
}
.login {
  margin: 0 auto;
  width: 290px;
}
.min-h-screen {
  min-height: calc(var(--vh, 1vh) * 100);
}

.surface-card {
  background-color: white;
}
</style>
