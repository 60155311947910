import { computed } from "vue";
import store from "../store";
import api from "../utils/api";

export function useUserStore() {
  const users = computed(() => store.state.users);
  const userSuggestions = computed(() => store.state.userSuggestions);

  async function fetchUsers() {
    try {
      const response = await api.get("/api/users");
      store.mutations.addModel("users", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  async function fetchUserById(userId) {
    try {
      const existing = users.value.find((u) => u.id === userId);
      if (existing) {
        return existing;
      }
      store.mutations.addModel("users", { id: userId });
      const response = await api.get(`/api/users/${userId}`);
      store.mutations.updateModel("users", userId, response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user by id:", error);
      throw error;
    }
  }

  async function createUser(userData) {
    try {
      const response = await api.post("/api/users", userData);
      store.mutations.addModel("users", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }

  async function updateUser(userId, userData) {
    try {
      const response = await api.put(`/api/users/${userId}`, userData);
      store.mutations.updateModel("users", userId, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  }

  async function deleteUser(userId) {
    try {
      await api.delete(`/api/users/${userId}`);
      store.mutations.removeModel("users", userId);
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  }

  async function fetchUserSuggestions(query) {
    try {
      const response = await api.get(`/api/users/suggestions`, {
        params: { query },
      });
      store.mutations.setUserSuggestions(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user suggestions:", error);
      throw error;
    }
  }
  const getUserName = (userId) => {
    return (
      users.value?.find((u) => u.id === userId)?.username || "Unknown User"
    );
  };

  const getUserProfilePicture = (userId) => {
    return (
      users.value?.find((u) => u.id === userId)?.profilePicture ||
      `https://placehold.co/100x100/EEE/31343C?font=open-sans&text=${
        getUserName(userId)[0]
      }`
    );
  };

  return {
    users,
    userSuggestions,
    fetchUsers,
    fetchUserById,
    createUser,
    updateUser,
    deleteUser,
    fetchUserSuggestions,
    getUserName,
    getUserProfilePicture,
  };
}
