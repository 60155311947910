import { computed, watch, onMounted, onUnmounted } from "vue";
import store from "../store";
import api from "../utils/api";

let refreshTimeout = null;

export function useAuthStore() {
  const user = computed(() => store.state.user);
  const isAuthenticating = computed(() => !!store.state.isAuthenticating);
  const isAuthenticated = computed(() => !!store.state.token);
  const token = computed(() => store.state.token);
  const refreshToken = computed(() => store.state.refreshToken);

  function parseJwt(token) {
    if (!token) return null;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  async function scheduleTokenRefresh() {
    if (!token.value) return;

    const decodedToken = parseJwt(token.value);
    if (!decodedToken) return;

    const expiresAt = decodedToken.exp * 1000;
    const refreshAt = expiresAt - 60 * 1000; // Refresh 1 minute before expiration
    const delay = refreshAt - Date.now();
    console.log("scheduleTokenRefresh", delay);
    if (delay > 0) {
      refreshTimeout = setTimeout(async () => {
        try {
          await refreshAccessToken();
        } catch (error) {
          console.error("Error refreshing token:", error);
          clearTimeout(refreshTimeout);
        }
      }, delay);
    } else {
      await refreshAccessToken(); // Refresh immediately if the token is close to expiration
    }
  }

  async function validateTokenRefresh(token, refreshToken, user) {
    if (!token) return;

    const decodedToken = parseJwt(token);
    if (!decodedToken) return;

    const expiresAt = decodedToken.exp * 1000;
    const refreshAt = expiresAt - 60 * 1000; // Refresh 1 minute before expiration
    const delay = refreshAt - Date.now();
    console.log("validateTokenRefresh", delay);
    if (delay <= 0) {
      const response = await api.post("/auth/refresh-token", {
        token: refreshToken,
      });
      console.log("response", response.data);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
      store.mutations.setUser(response.data.user);
    } else {
      store.mutations.setToken(token);
      store.mutations.setRefreshToken(refreshToken);
      store.mutations.setUser(user);
    }
    return;
  }

  async function verifyTwoFactor(payload) {
    try {
      const response = await api.post("/auth/verify-2-factor", payload);
      store.mutations.setUser(response.data.user);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
      return response;
    } catch (error) {
      console.error("Error verifying 2FA code:", error);
      throw error;
    }
  }

  async function login(credentials) {
    try {
      const response = await api.post("/auth/login", credentials);
      store.mutations.setUser(response.data.user);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
      scheduleTokenRefresh(); // Schedule token refresh after login
      return response;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  }

  async function refreshAccessToken() {
    try {
      const response = await api.post("/auth/refresh-token", {
        token: refreshToken.value,
      });
      console.log("response", response.data);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  }

  async function logout() {
    try {
      store.mutations.clearAuth();
      clearTimeout(refreshTimeout); // Clear the timeout when logging out
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  }

  async function verifyEmailToken(token) {
    try {
      const response = await api.post("/auth/verify-email", { token });
      store.mutations.setUser(response.data.user);
      return response.data;
    } catch (error) {
      console.error("Error verifying email token:", error);
      throw error;
    }
  }

  async function resetPassword(email) {
    try {
      await api.post("/auth/reset-password", { email });
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  }

  async function updateProfile(profileData) {
    try {
      const response = await api.put("/auth/update-profile", profileData);
      store.mutations.setUser(response.data.user);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  }

  onMounted(() => {
    if (token.value) {
      scheduleTokenRefresh(); // Schedule token refresh when the hook is mounted
    }
  });

  onUnmounted(() => {
    clearTimeout(refreshTimeout); // Clean up the timeout when the hook is unmounted
  });

  watch(token, () => {
    if (token.value) {
      scheduleTokenRefresh(); // Reschedule token refresh when the token changes
    } else {
      clearTimeout(refreshTimeout); // Clear the timeout if the token is removed
    }
  });

  return {
    user,
    isAuthenticated,
    isAuthenticating,
    token,
    refreshToken,
    verifyTwoFactor,
    login,
    logout,
    refreshAccessToken,
    verifyEmailToken,
    resetPassword,
    updateProfile,
    validateTokenRefresh,
  };
}
