<template>
  <Dialog
    v-model:visible="showModal"
    header="Activty"
    :modal="true"
    @update:visible="
      (v) => {
        if (!v) close();
      }
    "
    class="activity-modal"
    :closable="true"
    :draggable="false"
  >
    <div v-if="isLoading" class="text-center">
      <ProgressSpinner />
    </div>

    <template v-else>
      <!-- activity List -->
      <div class="activities-container">
        <ul class="activities-list" v-if="activities?.length > 0">
          <li
            v-for="activity in activities"
            :key="activity.id"
            class="activity"
          >
            <div class="activity-header">
              <img
                :src="getUserProfilePicture(activity.userId)"
                class="activity-avatar"
              />
              <p class="activity-body flex-1">
                <span class="activity-user-name">
                  {{ getUserName(activity.userId) }}
                </span>
                <span v-if="activity.action === 'update'">
                  updated the
                  <span
                    v-for="(detail, key, idx) in activity.details"
                    :key="idx"
                  >
                    <strong>{{ formatKey(key) }}</strong>
                    from
                    <span v-html="detail.oldValue || 'nothing'"></span>
                    to
                    <span v-html="detail.newValue || 'nothing'"></span>
                    <span v-if="idx < Object.keys(activity.details).length - 1"
                      >,
                    </span> </span
                  >.
                </span>
                <span v-else-if="activity.action === 'create'">
                  created a new
                  <strong>{{ activity.modelType }}</strong> with
                  <span
                    v-for="(detail, key, idx) in activity.details"
                    :key="idx"
                  >
                    <strong>{{ formatKey(key) }}</strong
                    >:
                    <span v-html="detail.newValue || 'nothing'"></span>
                    <span v-if="idx < Object.keys(activity.details).length - 1"
                      >,
                    </span> </span
                  >.
                </span>
              </p>
            </div>

            <!-- Timestamp -->
            <div class="activity-actions">
              <small class="text-muted">{{
                formatDate(activity.timestamp)
              }}</small>
            </div>
          </li>
        </ul>
        <p class="text-center" v-else>No activty found.</p>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useActivityStore } from "../../hooks/useActivityStore";
import { useUserStore } from "../../hooks/useUserStore";
// import { useAuthStore } from "../../hooks/useAuthStore";
import { safeBackRoute } from "../../utils/helpers";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
// import Button from "primevue/button";

const props = defineProps({ noteId: String });
const router = useRouter();
const route = useRoute();

const { fetchUserById, getUserName, getUserProfilePicture } = useUserStore();
const { activities, isLoading, fetchactivities } = useActivityStore();
// const { isAuthenticated } = useAuthStore();

const showModal = ref(true);

// Fetch activities on component mount
onMounted(() => {
  fetchactivities({ noteId: props.noteId });
});

watch(activities, () => {
  activities?.value?.forEach((activity) => fetchUserById(activity.userId));
});

const formatKey = (key) => {
  // Make the keys user-friendly
  switch (key) {
    case "content":
      return "content";
    case "color":
      return "color";
    case "shareUrl":
      return "share URL";
    case "title":
      return "title";
    case "userId":
      return "user ID";
    default:
      return key;
  }
};
const formatDate = (date) => {
  const now = new Date();
  const daysDiff = differenceInDays(now, date);
  const hoursDiff = differenceInHours(now, date);
  const minutesDiff = differenceInMinutes(now, date);

  if (daysDiff > 0) {
    return `${daysDiff}d`;
  } else if (hoursDiff > 0) {
    return `${hoursDiff}h`;
  } else {
    return `${minutesDiff}m`;
  }
};
const close = () => {
  const currentQuery = route.query;
  const path = safeBackRoute();
  router.push({ path, query: currentQuery });
};
</script>

<style>
.activity-actions {
  margin-left: 46px;
}
.activity-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  float: left;
}
.activity-user-name {
  font-weight: 600;
  margin-right: 4px;
}
.activity-modal {
  width: 600px;
  max-width: 100%;
  height: 80vh;
  border-radius: 10px !important;
}
.activity-modal .p-dialog-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.activity-user-name {
  margin: 0;
}
.activities-container {
  flex: 1;
  overflow-y: auto;
}

.activities-list {
  list-style-type: none;
  padding: 0;
}

.activity {
  margin-bottom: 1.5rem;
}
</style>
