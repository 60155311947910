<template>
  <div class="sub-panel">
    <p class="sub-panel-title">Pick place</p>
    <div class="split-button-toggle">
      <Button
        label="Arrive"
        :severity="localLocationTrigger === 'arrive' ? 'primary' : 'secondary'"
        @click="localLocationTrigger = 'arrive'"
      />
      <Button
        label="Depart"
        :severity="localLocationTrigger === 'leave' ? 'primary' : 'secondary'"
        @click="localLocationTrigger = 'leave'"
      />
    </div>
    <AutoComplete
      v-model="localSelectedLocation"
      :suggestions="filteredLocations"
      @complete="searchAddress"
      field="name"
      :minLength="3"
      :delay="600"
      placeholder="Choose a location"
      optionLabel="display_name"
      class="w-full"
      inputClass="w-full auto-complete"
      appendTo="body"
    />
    <div class="flex gap-1 mt-2 w-full">
      <Button
        label="Back"
        text
        icon="pi pi-arrow-left"
        class="flex-1 justify-content-start"
        @click="
          (e) => {
            e.stopPropagation();
            showPanel('main');
          }
        "
      />
      <Button label="Save" class="flex-1" icon="pi pi-check" @click="save" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, onMounted } from "vue";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import { useLocationStore } from "../../hooks/useLocationStore";

const props = defineProps({
  selectedLocation: String,
  locationTrigger: { type: String, default: "arrive" },
});

const emit = defineEmits([
  "update:selectedLocation",
  "update:locationTrigger",
  "showPanel",
  "save",
]);

const { typeaheadLocations } = useLocationStore();

const filteredLocations = ref(null);
const localSelectedLocation = ref(props.selectedLocation);
const localLocationTrigger = ref(props.locationTrigger);

watch(localSelectedLocation, (newLocation) =>
  emit("update:selectedLocation", newLocation)
);
watch(localLocationTrigger, (newTrigger) =>
  emit("update:locationTrigger", newTrigger)
);
if (localLocationTrigger.value <= null) {
  localLocationTrigger.value = "arrive";
}
const save = () => {
  emit("save");
};

const showPanel = (panel) => {
  emit("showPanel", panel);
};

const searchAddress = async ({ query }) => {
  filteredLocations.value = null;
  const res = await typeaheadLocations(query);
  filteredLocations.value = res;
};

onMounted(() => {
  document.querySelector(".sub-panel .auto-complete")?.focus();
});
</script>
