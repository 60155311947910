<template>
  <div :class="['files-grid', `layout-${files?.length}`]">
    <div
      v-for="(file, index) in visibleFiles"
      :key="file.id"
      :class="['file-item', getClassForIndex(index, visibleFiles.length)]"
      @click="(e) => openModal(index, e)"
    >
      <div v-if="isUploading(file)" class="preview-media">
        <p>Uploading {{ file.name }}...</p>
        <progress
          :value="getProgress(file)"
          max="100"
          class="block mb-2 w-full"
        ></progress>
        <Button @click="cancelUpload(noteId, file.id)" outlined>Cancel</Button>
      </div>
      <template v-else>
        <img
          v-if="isImage(file.filePath)"
          :src="file.signedUrl"
          class="preview-media"
          crossOrigin="anonymous"
        />

        <div class="preview-media" v-else-if="isAudio(file.filePath)">
          <audio
            :src="file.signedUrl"
            controls
            class="preview-media"
            crossOrigin="anonymous"
          ></audio>
        </div>

        <div class="preview-media" v-else-if="isVideo(file.filePath)">
          <Button text icon="pi pi-ellipsis-v" class="open" />
          <video
            :src="file.signedUrl"
            controls
            preload="metadata"
            @click="(e) => e.preventDefault()"
            crossOrigin="anonymous"
          ></video>
        </div>

        <PdfViewer
          v-else-if="isPDF(file.previewKey || file.filePath)"
          :signedUrl="file.previewSignedUrl || file.signedUrl"
        />

        <div v-else class="file-name">{{ file.name }}</div>

        <div
          v-if="remainingCount > 0 && index === visibleFiles?.length - 1"
          class="file-item more-files"
        >
          +{{ remainingCount }} more
        </div>
      </template>
    </div>

    <Dialog
      v-model:visible="isModalOpen"
      :fullScreen="true"
      :closable="false"
      @hide="closeModal"
      class="media-dialog"
    >
      <div class="flex gap-1 mt-2">
        <Button text icon="pi pi-times" @click="closeModal" />
        <div class="flex-1" />
        <Button icon="pi pi-download" @click="downloadCurrentFile" />
        <Button
          icon="pi pi-pencil"
          v-if="isImage(currentFile.filePath) || isVideo(currentFile.filePath)"
          @click="openPhotoEditor"
        />
      </div>

      <div
        class="modal-content"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <button
          @click="previousFile"
          class="nav-button prev-button"
          v-if="files?.length > 1"
        >
          ‹
        </button>
        <div ref="zoomContainer" class="zoom-container">
          <div v-if="isUploading(currentFile)" class="modal-media">
            <p>Uploading {{ currentFile.name }}...</p>
            <progress
              :value="getProgress(currentFile)"
              max="100"
              class="block mb-2 w-full"
            ></progress>
            <Button @click="cancelUpload(noteId, currentFile.id)" outlined
              >Cancel</Button
            >
          </div>

          <!-- Image Modal -->
          <img
            v-if="isImage(currentFile.filePath)"
            :src="currentFile.signedUrl"
            class="modal-media"
            ref="modalImage"
            crossOrigin="anonymous"
          />

          <!-- Audio Modal -->
          <audio
            v-else-if="isAudio(currentFile.filePath)"
            :src="currentFile.signedUrl"
            controls
            class="modal-media"
            crossOrigin="anonymous"
          ></audio>

          <!-- Video Modal -->
          <video
            v-else-if="isVideo(currentFile.filePath)"
            :src="currentFile.signedUrl"
            controls
            preload="auto"
            muted
            autoplay
            playsinline
            class="modal-media"
            crossOrigin="anonymous"
          ></video>

          <!-- PDF Modal -->
          <PdfViewer
            v-else-if="isPDF(currentFile.previewKey || currentFile.filePath)"
            :signedUrl="currentFile.previewSignedUrl || currentFile.signedUrl"
          />
        </div>
        <button
          @click="nextFile"
          class="nav-button next-button"
          v-if="files?.length > 1"
        >
          ›
        </button>
      </div>
      <Dialog
        v-model:visible="isPhotoEditorOpen"
        :fullScreen="true"
        :closable="false"
        @hide="isPhotoEditorOpen = false"
        class="media-dialog"
      >
        <PhotoEditorDialog
          v-if="isPhotoEditorOpen"
          :file="currentFile"
          @close="isPhotoEditorOpen = false"
          @save="handleSaveEdits"
        />
      </Dialog>
    </Dialog>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  toRefs,
  computed,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import PdfViewer from "./PdfViewer.vue"; // Import the PDF Viewer component
import PhotoEditorDialog from "./PhotoEditorDialog.vue"; // Import your photo editor component
import { useUploadStore } from "../../hooks/useUploadStore"; // Function to save the edited image
import { useSwipeHandler } from "../../hooks/useSwipe";
import { isImage, isAudio, isVideo, isPDF } from "../../utils/helpers";

const {
  uploadFile,
  uploadProgress,
  uploadStatus,
  cancelUpload,
  getUploadingFilesByNoteId,
} = useUploadStore();
const { handleTouchStart, handleTouchEnd } = useSwipeHandler({
  onSwipeLeft: () => {
    nextFile();
  },
  onSwipeRight: () => {
    previousFile();
  },
  onSwipeDown: () => {
    isModalOpen.value = false;
  },
});
const props = defineProps({
  files: Array,
  noteId: {
    type: String,
    required: true,
  },
  limit: {
    type: Number,
    default: 5, // Number of files to show in the grid
  },
});

const { files, noteId } = toRefs(props);
const isModalOpen = ref(false);
const isPhotoEditorOpen = ref(false);
const modalFileIndex = ref(null);
const pendingFiles = getUploadingFilesByNoteId(props.noteId);

// Fetch files being uploaded for the noteId
const visibleFiles = computed(() => {
  return [...(files.value ?? []), ...(pendingFiles.value ?? [])].slice(
    0,
    props.limit
  );
});

const remainingCount = computed(() => {
  return (
    [...(files.value ?? []), ...(pendingFiles.value ?? [])].length - props.limit
  );
});

const isUploading = (file) => {
  return uploadStatus.value[`${noteId.value ?? "new"}-${file.id}`];
};

const getProgress = (file) => {
  return uploadProgress.value[noteId.value ?? "new"]?.[file.id] || 0;
};

const openModal = (index, e) => {
  e.stopPropagation();
  modalFileIndex.value = index;
  isModalOpen.value = true;
  document.body.style.overflow = "hidden";
};

const closeModal = () => {
  isModalOpen.value = false;
  modalFileIndex.value = null;
  document.body.style.overflow = "";
};

const previousFile = () => {
  modalFileIndex.value =
    modalFileIndex.value > 0
      ? modalFileIndex.value - 1
      : files.value.length - 1;
};

const nextFile = () => {
  modalFileIndex.value =
    modalFileIndex.value < files.value.length - 1
      ? modalFileIndex.value + 1
      : 0;
};

const currentFile = computed(() => files.value[modalFileIndex.value] || {});

const downloadCurrentFile = () => {
  const link = document.createElement("a");
  link.href = currentFile.value.signedUrl;
  link.download = currentFile.value.name;
  link.click();
};

const openPhotoEditor = () => {
  isPhotoEditorOpen.value = true;
};

const handleSaveEdits = async (editedFile) => {
  // Upload the edited image
  const savedFile = await uploadFile(editedFile);
  console.log("TODO: save to note", savedFile);
  isPhotoEditorOpen.value = false;
};

// Handle keyboard navigation
const handleKeydown = (event) => {
  if (event.key === "ArrowLeft") {
    previousFile();
  } else if (event.key === "ArrowRight") {
    nextFile();
  } else if (event.key === "Escape") {
    closeModal();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeydown);
});

// Method to get the appropriate class based on the index and total length
const getClassForIndex = (index, length) => {
  const isImg = isImage(files.value[index]?.filePath);
  const isAdo = isAudio(files.value[index]?.filePath);
  const is_pdf = isPDF(
    files.value[index]?.previewKey || files.value[index]?.filePath
  );
  const elm = isImg ? "img" : isAdo ? "audio" : is_pdf ? "pdf" : "video";
  if (length === 1) return `one ${elm}`;
  if (length === 2) return `two ${elm}`;
  if (length === 3) return index === 0 ? `one ${elm}` : `two ${elm}`;
  if (length === 4) return `two ${elm}`;
  if (length >= 5) return index === 0 ? `two ${elm}` : `three ${elm}`;
  return `three ${elm}`; // Fallback for more than 5 files
};
</script>

<style>
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 1000;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.zoom-container {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom-container video {
  width: 70%;
}
.media-dialog .p-dialog-content {
  height: 100%;
  flex: 1;
  padding-top: constant(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  /* display: flex;
  align-items: center; */
}
audio {
  display: block !important;
  width: 80%;
}

video {
  max-width: 80vw;
}
</style>

<style>
.files-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.file-item {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
}
.file-item img {
  top: 0;
  left: 0;
  width: 100%;
}
.file-preview {
  width: 100%;
  max-height: 100%;
  /* height: 100%; */
  object-fit: cover;
}
.p-button.open {
  position: absolute;
  right: 10px;
}
.one {
  grid-column: span 2;
  grid-row: span 2;

  width: 100%;
}
.one {
  padding-top: 100%;
}
.one.img {
  padding-top: 100%;
}
.one img {
  position: absolute;
}

.pdf .pdf-container {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
}
.pdf .pdf-navigation {
  display: none;
}
.two {
  grid-column: span 1;
  grid-row: span 1;
  width: 48%;
}
.two {
  padding-top: 50%;
}

.three {
  grid-column: span 1;
  grid-row: span 1;
  /* aspect-ratio: 1 / 1; */
  width: 32%;
  padding-top: 33.3333%;
}
.three.img {
  /* padding-top: 33.3333%; */
}

.three img {
  position: absolute;
}
.more-files {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 18px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* max-width: 90%; */
  max-height: 90%;

  height: 100%;
  flex: 1;
  overflow-y: auto;
  justify-content: center;
}
.pinch-zoom-container {
  width: 100%;
}
.modal-media {
  max-width: 100%;
  max-height: 80vh;
  cursor: pointer;
}
.preview-media {
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
  border: 1px solid var(--corp-primary-color);
  border-radius: 10px;
}
.preview-media video {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.preview-media audio {
  width: 100%;
  margin: 0 auto;
}
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px 6px;
  z-index: 1000;
}

.prev-button {
  left: 0px;
}

.next-button {
  right: 0px;
}

.zoom-container {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-dialog .p-dialog-content {
  height: 100%;
  flex: 1;
  /* display: flex;
  align-items: center; */
  padding: 0;
}
</style>

<style>
.files-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  position: relative;
}

.file-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.file-item img {
  top: 0;
  left: 0;
  width: 100%;
}
.file-preview {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* max-width: 90%; */
  max-height: 90%;

  height: 100%;
  flex: 1;
  overflow-y: auto;
  justify-content: center;
}
.pinch-zoom-container {
  width: 100%;
}
.modal-image {
  max-width: 100%;
  max-height: 80vh;
  cursor: pointer;
}
</style>
