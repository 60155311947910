<template>
  <div class="notes-page flex">
    <!-- Sidebar with Filters Component for Desktop -->
    <div class="spacer hidden md:block">
      <div class="pagesidebar shadow-2">
        <h3 class="">Filters</h3>
        <FiltersComp class="hidden md:block" />
      </div>
    </div>
    <!-- Main Content -->
    <div class="main-content flex-1">
      <div class="note-input">
        <NoteComp />
      </div>

      <div class="px-2 sm:px-3 pt-4">
        <div
          class="date-header flex justify-content-between align-items-center"
        >
          <!-- Filters Modal for Tablet and Mobile -->
          <div class="filters-modal md:hidden">
            <Button
              icon="pi pi-filter"
              class="p-button-outlined mr-2"
              severity="secondary"
              @click="showFilters = true"
            />
            <Dialog
              v-model:visible="showFilters"
              modal
              class="filter-modal"
              header="Filters"
            >
              <FiltersComp />
            </Dialog>
          </div>
          <div class="sort">
            <Select
              id="sort-filter"
              :model-value="filters.sort"
              @change="
                (e) => {
                  console.log('change');
                  updateFilter('sort', e.value);
                }
              "
              :options="sortOptions"
              placeholder="Sort by"
              class="w-full"
              optionLabel="label"
              optionValue="value"
            >
              <template #footer>
                <hr />
                <div class="my-3">
                  <input
                    type="checkbox"
                    class="mr-2"
                    id="group-by-pinned"
                    :checked="groupByPinned"
                    @change="groupByPinned = !groupByPinned"
                  />
                  <label for="group-by-pinned">Group by pinned</label>
                </div>
              </template>
            </Select>
          </div>

          <div class="flex-1" />
          <div class="view-switcher flex gap-1">
            <Button
              severity="secondary"
              icon="pi pi-th-large"
              class="p-button-outlined"
              @click="viewMode = 'grid'"
            />
            <Button
              severity="secondary"
              icon="pi pi-list"
              class="p-button-outlined"
              @click="viewMode = 'list'"
            />
            <!-- <Button
              severity="secondary"
              icon="pi pi-calendar"
              class="p-button-outlined"
              @click="viewMode = 'calendar'"
            /> -->
          </div>
        </div>
        <div v-if="isLoading" class="copy mt-8">Loading...</div>
        <template v-else-if="notes.length">
          <div class="flex mt-4">
            <label class="font-bold pin-label" v-if="groupByPinned"
              >Pinned</label
            >
            <div class="flex-1" />
          </div>
          <div :class="`notes-${viewMode} mb-4`">
            <template v-for="note in notes" :key="note.id">
              <div class="note-card" v-if="note.isPinned || !groupByPinned">
                <NoteComp :note="note" :viewMode="viewMode" />
              </div>
            </template>
          </div>
          <div class="flex mt-4" v-if="groupByPinned">
            <label class="font-bold pin-label">Unpinned</label>
            <div class="flex-1" />
          </div>
          <div :class="`notes-${viewMode} mb-4`" v-if="groupByPinned">
            <template v-for="note in notes" :key="note.id">
              <div class="note-card" v-if="!note.isPinned">
                <NoteComp :note="note" :viewMode="viewMode" />
              </div>
            </template>
          </div>
        </template>
        <p v-else class="copy mt-8">No notes available...</p>
      </div>
    </div>
    <NotePage
      v-if="id && !showComments && !showActivity"
      :id="id"
      :preview="preview"
    />
    <NoteComments v-if="id && showComments" :noteId="id" />
    <NoteActivity v-if="id && showActivity" :noteId="id" />
  </div>
</template>

<script setup>
import "vue3-carousel/dist/carousel.css";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import Select from "primevue/select";

import Dialog from "primevue/dialog";
import NotePage from "./NotePage";
import NoteComments from "../components/NoteComp/NoteComments.vue";
import NoteActivity from "../components/NoteComp/NoteActivity.vue";
import NoteComp from "../components/NoteComp";
import FiltersComp from "../components/FiltersComp";
import { useNoteStore } from "../hooks/useNoteStore";
import { useFilters } from "../hooks/useFilters";
import { isMobile } from "../utils/helpers";

const { filters, sortOptions, updateFilter, query, initialized } = useFilters();
const { notes, fetchNotes, isLoading } = useNoteStore();
const groupByPinned = ref(false);

const route = useRoute();

const id = computed(() => route.params.id);
const showComments = computed(() => route.path.includes("comments"));
const showActivity = computed(() => route.path.includes("activity"));
const preview = computed(() => route.meta?.preview === true);

const showFilters = ref(false);

const viewMode = ref(isMobile ? "list" : "grid");

watch(
  [query, initialized],
  () => {
    if (initialized.value) {
      fetchNotes(query.value);
    }
  },
  { deep: true, immediate: true }
);
</script>

<style scoped>
.filters-modal {
  /* margin-bottom: 1rem; */
}
.notes-page {
  display: flex;
  min-height: 100%;
  width: 100%;
}
.notes-page .pin-label {
  color: var(--input-clr);
}
.note-input {
  height: 50px;
  position: sticky;
  top: 10px;
}
.copy {
  color: var(--corp-text-color);
}
.spacer {
  width: 250px;
}
.pagesidebar {
  width: 250px;
  background-color: var(--filters-back);
  color: var(--corp-text-color);
  padding: 1rem;
  position: fixed;
  overflow: auto;
  height: calc(100vh - 50px);
}

.main-content {
  flex: 1;
  padding-bottom: 50vh;
  overflow-y: auto;
}

.notes-lists {
  list-style-type: none;
  padding: 0;
}

.note-list-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #eaeaea;
}

.note-card {
}
.sort {
  text-align: left;
  /* font-size: 0.7rem; */
}
.sort label {
  /* color: var(--corp-text-color); */
}
@media (max-width: 767px) {
  .pagesidebar {
    display: none;
  }
}
</style>
