<template>
  <div class="icon" v-tooltip.bottom="'Files'" @click="triggerFileInput">
    <i class="pi pi-image"></i>
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      multiple
      hidden
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, toRefs } from "vue";
import { useUploadStore } from "../../hooks/useUploadStore";
import { useFileStore } from "../../hooks/useFileStore";

const emit = defineEmits(["uploaded"]);
const props = defineProps({ noteId: String });
const { noteId } = toRefs(props);
const fileInput = ref(null);
const { createNoteFile } = useFileStore();
const { uploadFile } = useUploadStore();

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileChange = async (event) => {
  const files = Array.from(event.target.files);
  if (files.length) {
    let newFiles = [];
    const uploadPromises = files.map(async (file) => {
      const newFile = await uploadFile(file, noteId.value);
      if (noteId.value) {
        const res = await createNoteFile([newFile.id], noteId.value);
        newFiles = [...newFiles, ...res];
      } else {
        newFiles = [...newFiles, newFile];
      }
    });

    await Promise.all(uploadPromises);
    if (!noteId.value) {
      emit("uploaded", newFiles);
    }
  }
  fileInput.value.value = ""; // Clear the input to allow re-upload of the same file
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>
