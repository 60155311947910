<template>
  <Header />
  <Toast>
    <template #message="slotProps">
      <div class="flex-1 toast-bck">
        <div class="font-semibold text-sm my-1 flex px-2">
          Reminder
          <div class="flex-1" />
          <LiveTimeFromNow :time="slotProps.message.start" />
        </div>
        <button
          :style="slotProps.message.color"
          @click="slotProps.message.onClick"
          class="w-full text-left border-none outline-0 p-2 border-round-md"
        >
          <div class="font-medium text-lg my-1">
            {{ slotProps.message.summary }}
          </div>
          <div class="font-medium text-sm my-1">
            {{ slotProps.message.detail }}
          </div>
          <span class="font-bold text-primary">View Details</span>
        </button>
      </div>
    </template></Toast
  >
  <div class="content">
    <Sidebar v-if="isAuthenticated" />
    <router-view v-if="!isAuthenticating" />
  </div>
</template>

<script setup>
import { watch } from "vue";
import { Capacitor } from "@capacitor/core";
import Header from "./components/HeaderComp.vue";
import Sidebar from "./components/SidebarComp.vue";
import Toast from "primevue/toast";
import { useAuthStore } from "./hooks/useAuthStore";
import { useDeviceLocation } from "./hooks/useDeviceLocation";
import { useInvitationStore } from "./hooks/useInvitationStore";
import { useWebSocket } from "./hooks/useWebsocketConnection";
import { usePushNotifications } from "./hooks/usePushNotifications";

import LiveTimeFromNow from "./components/LiveTimeFromNow.vue";
useWebSocket();
const { isAuthenticated, isAuthenticating } = useAuthStore();
const { startLocationTracking, stopLocationTracking } = useDeviceLocation();
const { startInviteLoop, stopInviteLoop } = useInvitationStore();

watch(
  isAuthenticated,
  () => {
    if (isAuthenticated.value) {
      startLocationTracking();
      startInviteLoop();
      if (
        Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android"
      ) {
        usePushNotifications();
      }
    } else {
      stopLocationTracking();
      stopInviteLoop();
    }
  },
  { immediate: true }
);
function updateHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

// Update the height on page load and on window resize
window.addEventListener("resize", updateHeight);
window.addEventListener("load", updateHeight);
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
}
.content {
  position: relative;
  display: flex;
  flex-grow: 1;
}
</style>
