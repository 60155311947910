import { nextTick, toRefs } from "vue";
// import { debounce } from "@/utils/helpers";
import { useTaskStore } from "../../../hooks/useTaskStore";

export function useTasks(props, onChange) {
  const { tasks, noteId } = toRefs(props);
  const { createTask, updateTask, deleteTask } = useTaskStore(); // Assuming you have an API method to save the task

  const debounceCb = async (task, offset) => {
    if (noteId.value) {
      console.log("task", task);
      if (task.isTmp) {
        let tmp = { ...task };
        delete tmp.id;
        delete tmp.isTmp;
        // let newTask = { ...tmp };

        await createTask(tmp);
        // console.log(JSON.stringify(tasks.value));
        // const index = tasks.value?.findIndex((t) => t.id === newTask.id);

        // tasks.value[index] = newTask;
        // console.log("debounceCb", task, index, JSON.stringify(tasks.value));
        onChange(tasks.value);
        nextTick(() => {
          if (offset) focusLastTask(offset);
        });
      } else {
        await updateTask(task.id, task);
      }
    }
  };
  // Debounced save function
  // const debouncedSaveTask = debounce((task) => {
  //   if (noteId.value) debounceCb(task);
  // }, 700); // 500ms debounce delay

  const addTask = (index) => {
    const val = tasks.value[index].description;
    if (val.length > 0 && index === tasks.value.length - 1) {
      tasks.value.push({
        id: `TMP_NEW-${Date.now()}`,
        description: "",
        completed: false,
        order: tasks.value.length,
        showMenu: false,
        isTmp: true,
        noteId: noteId.value,
      });
      debounceCb(tasks.value[index], 2);
    }
  };

  const removeTask = async (index) => {
    if (!tasks.value[index].isTmp) {
      await deleteTask(tasks.value[index].id, tasks.value[index].noteId);
    } else {
      tasks.value.splice(index, 1);
      onChange(tasks.value);
    }
  };

  const handleTaskEnter = (index, force) => {
    // if (index !== tasks.value.length - 1) {
    if (force || tasks.value[index]?.description?.trim() > "") {
      tasks.value.splice(index + 1, 0, {
        id: `TMP_NEW-${Date.now()}`,
        description: "",
        completed: false,
        order: index + 1,
        showMenu: false,
        isTmp: true,
        noteId: noteId.value,
      });
      nextTick(() => {
        focusTask(index + 1);
      });
      onChange(tasks.value);
    }
    // }
  };

  const handleTaskDelete = async (index, e) => {
    let prevVal = tasks.value[index].description;
    if (prevVal.length === 0) {
      e?.preventDefault();
      e?.stopPropagation();
    }
    if (index !== tasks.value.length - 1) {
      if (prevVal?.trim() === "") {
        removeTask(index);
        onChange(tasks.value);
        console.log("tasks.value", tasks.value);

        nextTick(() => {
          let previousIndex = index - 1;
          if (previousIndex < 0) {
            previousIndex = 0;
          }
          focusTask(previousIndex);
        });
      }
    }
  };

  const focusLastTask = (offset = 1) => {
    const idx = tasks.value.length === 1 ? 0 : tasks.value.length - offset;
    const lastTaskInput = document.querySelectorAll(
      `#${noteId.value ? `note-${noteId.value}` : "note-new"} .task-input`
    )[idx];
    if (lastTaskInput) lastTaskInput.focus();
  };

  const focusTask = (index) => {
    const taskInput = document.querySelectorAll(
      `#${noteId.value ? `note-${noteId.value}` : "note-new"} .task-input`
    )[index];
    if (taskInput) {
      taskInput.focus();
    }
  };

  const updateTaskFn = (index, value, autoSave) => {
    if (!value.id) {
      tasks.value[index] = value;
    } else {
      const idx = tasks.value.findIndex((task) => task.id === value.id);
      if (idx !== -1) tasks.value[idx] = value;
    }
    onChange(tasks.value);
    if (autoSave) {
      debounceCb(tasks.value[index]); // Save the task to the API with debounce
    }
  };

  const toggleTaskMenu = (index) => {
    tasks.value[index].showMenu = !tasks.value[index].showMenu;
  };

  const assignResponsible = (task) => {
    // Logic for assigning responsibility
    task.showMenu = false;
  };

  const addDueDate = (task) => {
    // Logic for adding due date
    task.showMenu = false;
  };

  return {
    tasks,
    addTask,
    removeTask,
    saveTask: debounceCb,
    handleTaskEnter,
    handleTaskDelete,
    focusLastTask,
    focusTask,
    updateTask: updateTaskFn,
    toggleTaskMenu,
    assignResponsible,
    addDueDate,
  };
}
