<template>
  <header class="header">
    <div class="trigger" v-if="isAuthenticated">
      <Button
        text
        @click="
          (e) => {
            e.sidebarHandled = true;
            toggleSidebar();
          }
        "
      >
        <i
          :class="`block md:hidden  pi pi-${
            !isSidebarOpen ? 'bars' : 'times'
          } `"
        />
        <i
          :class="`hidden md:block pi pi-${
            !isSidebarOpen ? 'bars' : 'filter'
          } `"
        />
      </Button>
    </div>

    <RouterLink to="/" class="logo">
      <div class="img" />
      <!-- <img src="/logo.png" /> -->
      <span>Storatic</span>
    </RouterLink>

    <!-- <input type="text" placeholder="Search" class="search-bar" /> -->
    <div class="icons" ref="menuRef" v-if="isAuthenticated">
      <NotificationsIcon />
      <CalendarIcon />
      <Button icon="pi pi-user" class="p-button-text" @click="toggleUserMenu" />
      <div v-if="showUserMenu" class="user-menu-flyout">
        <div
          :style="{
            backgroundImage: `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${user?.email?.charAt(
              0
            )}')`,
          }"
          alt="User Profile"
          class="profile-pic"
        />
        <p>{{ user?.email }}</p>
        <Divider />
        <Button
          label="Profile"
          icon="pi pi-cog"
          class="p-button-text"
          @click="goTo('/profile')"
        />
        <Button
          label="Change Password"
          icon="pi pi-lock"
          class="p-button-text"
          @click="goTo('/change-password')"
        />
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          class="p-button-text"
          @click="
            () => {
              logout();
              goTo('/login');
            }
          "
        />
        <Divider />
        <div class="footer-links">
          <a href="/privacy-policy">Privacy Policy</a> ·
          <a href="/terms-of-service">Terms of Service</a>
        </div>
      </div>
    </div>
    <div
      class="buttons flex gap-1"
      v-if="!isAuthenticating && !isAuthenticated"
    >
      <RouterLink to="/login">
        <Button label="Login" severity="primary" outlined />
      </RouterLink>
      <RouterLink to="/register">
        <Button label="Register" />
      </RouterLink>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useRouter, RouterLink } from "vue-router";
import Button from "primevue/button";
import Divider from "primevue/divider";
import CalendarIcon from "./CalendarIcon.vue";
import NotificationsIcon from "./NotificationsIcon.vue";
import { useAuthStore } from "../hooks/useAuthStore";
import { useSidebarState } from "../hooks/useSidebarState";

const { user, isAuthenticated, isAuthenticating, logout } = useAuthStore();
const { isSidebarOpen, toggleSidebar } = useSidebarState();
const showUserMenu = ref(false);
const menuRef = ref(null);
const router = useRouter();
window.router = router;

const toggleUserMenu = (e) => {
  if (e) {
    e.userHandled = true;
  }
  showUserMenu.value = !showUserMenu.value;
};

const goTo = (path) => {
  router.push(path);
  showUserMenu.value = false;
};

const handleClickOutside = (event) => {
  if (!event || event.userHandled) {
    return;
  }
  if (!document.querySelector(".user-menu-flyout").contains(event.target)) {
    showUserMenu.value = false;
  }
};
watch(showUserMenu, () => {
  if (showUserMenu.value) {
    document.addEventListener("click", handleClickOutside);
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
onMounted(() => {});

onBeforeUnmount(() => {});
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  z-index: 9;
  background-color: var(--app-back);

  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.trigger {
  width: 45px;
}
.logo {
  height: 34px;
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--logo-clr);
  display: flex;
  align-items: start;
  text-decoration: none;
  outline: none !important;
}
.logo .img {
  height: 34px;
  width: 40px;
  margin-right: 0.25rem;
  background-image: var(--logo);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.logo i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.search-bar {
  flex-shrink: 1;
  margin: 0 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 600px;
  background-color: var(--input-back);
  color: var(--input-clr);
}
.search-bar::placeholder {
  color: var(--input-clr);
}
.icons {
  display: flex;
  gap: 1rem;
  position: relative;
  justify-content: flex-end;
  flex: 1;
}
.icons i {
  color: var(--corp-text-color);
}

.user-menu-flyout {
  position: absolute;
  top: 50px;
  right: -10px;
  width: 300px;
  background-color: #333;
  color: white;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1001;
}

.user-menu-flyout::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 20px;
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.user-menu-flyout .profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto 10px;
  background-size: cover;
  background-position: center;
}

.user-menu-flyout .footer-links {
  margin-top: 10px;
}

.user-menu-flyout .p-button-text {
  width: 100%;
  justify-content: flex-start;
}
.p-button-text {
  /* color: white; */
  text-align: left;
  /* width: 100%; */
}

.footer-links a {
  color: #bbb;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
</style>
