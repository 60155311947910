<template>
  <span>{{ liveTime }}</span>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, defineProps } from "vue";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";

// Define the prop
const props = defineProps({
  time: {
    type: String,
    required: true,
  },
});

// Reactive variable to store the live time
const liveTime = ref("");

// Function to calculate and update the live time
const updateLiveTime = (time) => {
  const now = new Date();
  const targetTime = new Date(time);

  const seconds = differenceInSeconds(targetTime, now);
  const minutes = differenceInMinutes(targetTime, now);
  const hours = differenceInHours(targetTime, now);
  const days = differenceInDays(targetTime, now);
  console.log("seconds", seconds);

  if (seconds < 60 && seconds > -1) {
    liveTime.value = "now";
  } else if (minutes < 60 && minutes > -60) {
    liveTime.value = `${Math.abs(minutes)}m${minutes < -1 ? " ago" : ""}`; // X minutes
  } else if (hours < 24 && hours > -24) {
    liveTime.value = `${Math.abs(hours)}h${hours < -1 ? " ago" : ""}`; // X hours
  } else {
    liveTime.value = `${Math.abs(days)}d${days < -1 ? " ago" : ""}`; // X days
  }
};

// Automatically update the live time at the top of the minute
let interval;
onMounted(() => {
  updateLiveTime(props.time);

  // Calculate the milliseconds until the next top of the minute
  const now = new Date();
  const msUntilNextMinute =
    (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

  // Set a timeout to sync with the top of the minute
  setTimeout(() => {
    updateLiveTime(props.time); // Ensure it runs exactly at the top of the minute

    // After hitting the top of the minute, update every 60 seconds
    interval = setInterval(() => {
      updateLiveTime(props.time);
    }, 60000); // Update every minute
  }, msUntilNextMinute); // Initial delay until the next top of the minute
});

// Clear the interval when the component is unmounted
onBeforeUnmount(() => {
  clearInterval(interval);
});

// Watch for changes in the `time` prop and update accordingly
watch(
  () => props.time,
  (newTime) => {
    updateLiveTime(newTime);
  }
);
</script>
