<template>
  <div class="label-icon icon">
    <LabelIcon @click="toggleLabelMenu" v-tooltip.bottom="'Labels'" />
    <Dialog
      modal
      visible
      :draggable="false"
      :dismissableMask="false"
      :closable="false"
      v-if="showLabelMenu"
      class="label-menu"
    >
      <template #header>
        <span class="font-bold">Labels </span>
        <div class="flex-1" />
        <Button text icon="pi pi-times" @click="toggleLabelMenu" />
      </template>

      <div class="input-wrap">
        <div class="label-input-wrap">
          <InputText
            type="text"
            placeholder="Enter label name"
            v-model="searchTerm"
            @input="filterLabels"
            @keydown.enter="tryToSubmit"
            autoFocus
          />
        </div>
        <ul class="label-list">
          <li
            v-for="label in filteredLabels"
            :key="label.id"
            @click="toggleLabel(label, noteId)"
            class="label-item"
            :disabled="isSaving(label)"
          >
            <div class="input">
              <input
                v-if="!isSaving(label)"
                type="checkbox"
                :checked="isSelected(label)"
                class="checkbox"
              />

              <i v-else class="pi pi-spinner pi-spin icon" />
            </div>
            <span>{{ label.name }}</span>
          </li>
        </ul>
      </div>
      <div
        v-if="canCreateLabel"
        @click="createLabel({ name: searchTerm, noteId }, $event)"
        class="create-label"
      >
        <span>+ Create "{{ searchTerm }}"</span>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, nextTick, toRefs } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import { useLabels } from "./hooks/useLabels"; // Import the hook
import LabelIcon from "./LabelIcon.vue"; // The icon component for opening the label menu
const emit = defineEmits(["update:selectedLabels"]);
const props = defineProps({
  noteId: String,
  selectedLabels: Array,
});
const { selectedLabels } = toRefs(props);
const {
  searchTerm,
  showLabelMenu,
  filteredLabels,
  toggleLabelMenu,
  filterLabels,
  isSelected,
  isSaving,
  toggleLabel,
  canCreateLabel,
  createLabel,
} = useLabels(selectedLabels, (val) => {
  emit("update:selectedLabels", val);
});

watch(showLabelMenu, () => {
  if (showLabelMenu.value) {
    nextTick(() => {
      document.querySelector(".label-input")?.focus();
    });
  }
});
const tryToSubmit = () => {
  console.log("tryToSubmit");
  if (canCreateLabel.value) {
    createLabel({ name: searchTerm.value, noteId: props.noteId });
  }
};
</script>

<style>
.label-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-menu {
  background: var(--flyout-back);
  color: var(--corp-text-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
  width: 300px;
  max-width: 98%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 60vh;
}
.label-menu button {
  color: black;
}
.label-menu .p-dialog-content ul {
  padding: 0 0.5rem;
}
.note .input-wrap {
  background: var(--flyout-back);
  padding: 0 0.5rem 0.5rem;
  overflow-y: auto;
}
.note .header {
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: var(--flyout-back);
}
.label-input-wrap input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: var(--corp-inputtext-background) !important;
}
.label-input-wrap {
  position: sticky;
  top: 0;
  background: var(--flyout-back);
  padding: 0 8px 8px;
}
.label-list .input {
  height: 20px;
  width: 20px;
  text-align: center;
}
.label-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  flex: 1;
}

.label-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  cursor: pointer;
}

.create-label {
  cursor: pointer;
  color: #007bff;
  font-weight: 700;
  white-space: nowrap;
  text-align: left;
  padding: 0.5rem;
  background: var(--flyout-back-alt);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
