<template>
  <div class="tasks-container">
    <div v-for="(task, index) in tasks" :key="task.id" class="task-item">
      <template v-if="!preview && index !== tasks.length - 1">
        <div class="sort-handle" v-if="!preview && index !== tasks.length - 1">
          ⋮⋮
        </div>
        <i class="pi pi-plus" v-if="index === tasks.length - 1" />

        <input
          :disabled="readOnly"
          type="checkbox"
          :checked="task.completed"
          v-if="index !== tasks.length - 1"
          @change="
            updateTask(index, { ...task, completed: !task.completed }, true)
          "
        />
        <div
          :contenteditable="!readOnly"
          @input="
            (e) => {
              addTask(index);
              updateTask(index, {
                ...task,
                description: e.target.innerText,
              });
            }
          "
          @focus="() => (lastVal = task.description)"
          @blur="
            () => {
              if (task.description !== lastVal) {
                saveTask(task);
              }
              lastVal = null;
            }
          "
          @keydown.enter="
            (e) => {
              e.preventDefault();
              handleTaskEnter(index);
            }
          "
          @keydown.delete="handleTaskDelete(index, $event)"
          class="task-input"
          :placeholder="!task.description ? 'List item' : ''"
        >
          {{ task.description }}
        </div>
        <!-- <InputText
          :disabled="readOnly"
          :modelValue="task.description"
          @update:modelValue="
            (val) => updateTask(index, { ...task, description: val })
          "
          @input="addTask(index)"
          @focus="() => (lastVal = task.description)"
          @blur="
            () => {
              if (task.description !== lastVal) {
                saveTask(task);
              }
              lastVal = null;
            }
          "
          @keydown.enter="handleTaskEnter(index)"
          @keydown.delete="handleTaskDelete(index, $event)"
          placeholder="List item"
          class="task-input"
        /> -->

        <div
          class="task-more-menu"
          v-if="expanded && !readOnly && !preview && index !== tasks.length - 1"
        >
          <Button
            icon="pi pi-ellipsis-v"
            class="p-button-text"
            @click="toggleTaskMenu(index)"
          />
          <div v-if="task.showMenu" class="task-options-menu">
            <p @click="assignResponsible(task)">Assign Responsible</p>
            <p @click="addDueDate(task)">Add Due Date</p>
          </div>
        </div>
        <Button
          v-if="expanded && !readOnly && !preview && index !== tasks.length - 1"
          icon="pi pi-trash"
          class="p-button-text"
          @click="removeTask(index)"
        />
      </template>
    </div>
    <Button
      v-if="expanded"
      class="ml-1"
      text
      icon="pi pi-plus"
      label="Add Task Item"
      @click="handleTaskEnter(tasks.length - 1, true)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, nextTick } from "vue";
// import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useTasks } from "./hooks/useTasks"; // Import the hook

const emit = defineEmits(["update"]);

const props = defineProps({
  tasks: Array,
  preview: Boolean,
  expanded: Boolean,
  noteId: String,
  readOnly: Boolean,
});
const lastVal = ref("");
const {
  tasks,
  addTask,
  saveTask,
  removeTask,
  handleTaskEnter,
  handleTaskDelete,
  updateTask,
  toggleTaskMenu,
  assignResponsible,
  addDueDate,
  focusLastTask,
} = useTasks(props, (val) => {
  emit("update", val);
});

onMounted(() => {
  if (!props.noteId) {
    console.log("focus task");
    nextTick(() => {
      focusLastTask(2);
    });
  }
});
</script>

<style scoped>
.tasks-container {
  margin-top: 10px;
  text-align: left;
}

.task-item {
  display: flex;
  align-items: flex-start;

  gap: 2px;
  margin-bottom: 0.5rem;
  color: inherit;
}

.task-item input::placeholder {
  color: inherit;
}
.sort-handle {
  cursor: grab;
}

.task-input {
  width: 100%;
  border: none;
  padding: 0 0.5rem;
  outline: none;
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 2rem;
}

.task-more-menu {
  position: relative;
}

.task-options-menu {
  position: absolute;
  top: 20px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;
  z-index: 10;
}
.pi-plus {
  padding-left: 1.25rem;
}
.task-options-menu p {
  margin: 0;
  padding: 4px 8px;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
}

.task-options-menu p:hover {
  background-color: #f0f0f0;
}
</style>
